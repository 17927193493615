.flex-container {
  display: flex;
  flex-direction: column;
  max-width: 450px;
  width: 100%;
}

.logoBanner {
  cursor: pointer;
  margin-top: 6px;
  margin-bottom: 6px;
  margin-right: 12px;
  margin-left: 12px;
  display: flex;
  flex-direction: row;
  background-color: "#FFFFFF";
  box-shadow: "none";
  border-radius: 10px;
}

.logoBox {
  margin: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 63%;
}

.logoText {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: auto;
  background-color: #ffc42b;
}

.orderStatus {
  margin-top: 6px;
  margin-bottom: 6px;
  margin-right: 12px;
  margin-left: 12px;
  display: flex;
  flex-direction: column;
  border: "none";
  background-color: "#FFFFFF";
  box-shadow: "none";
}

.orderItem {
  margin-top: 6px;
  margin-bottom: 6px;
  margin-right: 12px;
  margin-left: 12px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  border: "none";
  background-color: "#FFFFFF";
  box-shadow: "none";
}

.priceDetail {
  margin-top: 6px;
  margin-bottom: 12px;
  margin-right: 12px;
  margin-left: 12px;
  display: flex;
  flex-direction: column;
  border: "none";
  background-color: "#FFFFFF";
  box-shadow: "none";
}
.adminCard {
  margin-top: 6px;
  margin-bottom: 12px;
  margin-right: 12px;
  margin-left: 12px;
  display: flex;
  flex-direction: column;
  border: "none";
  background-color: "#FFFFFF";
  box-shadow: "none";
  padding: 0 8px;
}

.border {
  border-top: 1px solid #c3c3c3;
}

.namecell {
  padding-right: 0%;
}

.popup {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 25px;
}

@keyframes progress-active {
  0% {
    opacity: 1;
    width: 0;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    width: 100%;
  }
}

.statusBar {
  position: relative;
}

.bar {
  content: "";
  opacity: 0;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background: #ff9843;
  border-radius: 0.28571429rem;
  -webkit-animation: progress-active 2s ease infinite;
  animation: progress-active 2s ease infinite;
}

.resize {
  position: absolute;
}

a:link {
  color: #1a84db;
  background-color: transparent;
  text-decoration: none;
}
a:visited {
  color: #1a84db;
  background-color: transparent;
  text-decoration: none;
}
